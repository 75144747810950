import styled from "styled-components";


export const IntelligentRoutingWrapper = styled.section`
    background: linear-gradient(180deg, #3B5CA7 1.22%, #428AC9 99.11%);
    clip-path: polygon(0 0, 100% 150px, 100% 100%, 0% 100%);
    color: #FFF;

    .routing-container{
        padding-top: 150px;
        margin: auto;

        .row{
            margin-left: 0;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1170px;
        }

        .images-container{
            position: relative;
            @media (min-width: 992px) {
                position: absolute;
                right: 0;
                height: 850px;
                .intelligent-routing-img, .acquirer-agnostic-img{
                    left: 50px;
                }
            }

            .intelligent-routing-img, .acquirer-agnostic-img{
                left: 75px;
                @media (max-width: 549px) {
                    left: 50px;
                }
            }

            @media (min-width: 1200px) {
                position: relative;
                height: auto;
            }

        }

        .text-container{
            @media (max-width: 991px) {
                max-width: 720px;
                margin: auto;
            }
            @media (max-width: 767px) {
                max-width: 540px;
            }
        }
    }
`;