import React from "react";
import { IntelligentRoutingWrapper } from "./IntelligentRoutingStyles"
import {Col, Row, Container} from "react-bootstrap"
import IntelligentRoutingImage from "./IntelligentRoutingImage";
import AcquirerAgnosticImage from "./AcquirerAgnosticImage"

const IntelligentRouting = () => (
<IntelligentRoutingWrapper className="">
    <div className="routing-container pb-0 pb-lg-5">
        <Row className="py-5 px-0 pr-lg-0">
            <Col xs={12} lg={5} className="px-3 px-sm-0 text-container mx-xl-auto">
                <h2 className="h1 Ambit font-weight-bold color-sky-blue mb-4">Intelligent Routing</h2>
                <p className="text-white">The typical enterprise level merchant requires integrations to many acquirers and PSPs in order to maximise their transactional acceptance rates whilst keeping costs at an acceptable minimum. </p>
                <p className="text-white">In the first instance it is crucial to have access to the best performing PSPs and acquirers at a regional and market level, but also to have additional providers available to allow for failed transactions to be retried to maximise the chances of acceptance. This is called auto re-routing, or cascading, and can make substantial improvements in reducing 'Do Not Honour' decline codes. </p>
                
                <h2 className="h1 Ambit font-weight-bold color-sky-blue mb-4 mt-5 pt-5">Acquirer Agnostic</h2>
                <p className="text-white">As the APEXX Payment Orchestration Platform is a connection hub without an APEXX owned acquiring platform we have no incentive to direct our merchant's transactions anywhere but to the optimal provider for that merchant. </p>
                <p className="text-white">Our merchants can simply use the granular data that is on offer from our platform to make an informed decision themselves as to which provider they will derive most benefit from sending specific portions of their transactional traffic to.</p>
            </Col>
            <Col xs={12} lg={7} className="pl-lg-5 px-0 pr-0 d-flex flex-column justify-content-between images-container mt-5 mt-lg-0 pl-5">
                <IntelligentRoutingImage/>
                <AcquirerAgnosticImage/>
            </Col>
        </Row>
    </div>
</IntelligentRoutingWrapper>
)

export default IntelligentRouting