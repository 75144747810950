import styled from "styled-components";

export const ProductVideoWrapper = styled.section`
    iframe{
        border-radius: 15px;
    }

    video {
        display: flex !important;
        opacity: 1 !important;
        cursor: pointer;
    }

    img{
        top: 0px;
        z-index: 1;
        position: absolute;
        width: 100%;
    }

    h2{
        color: #144056;
    }

    .embed-responsive:hover{
        .video-layover{
            opacity: 0.4;
        }
    }

    .video-layover{
        padding-top: 56.25%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        width: 100%;
        top: 0;
        opacity: 0.6;
        z-index: 1;
        transition: opacity 0.3s;

        &.active-video{
            padding-top: 0;
            width: 60px;
            height: 60px;
            border-radius: 25%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &::before{
                transform: translate(-50%,-50%);
                margin-left: 2px;
            }
        }

        &::before{
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid white;
            top: 50%;
            left: 50%;
            transform: translate(0, -50%);
        }

        &:hover{
            cursor: pointer;
        }
    }

`;