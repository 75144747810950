import React from "react"
import { ApmWrapper } from "./Apm.style"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { LINKS } from "../../../data/pageLinks.data"

const Apm = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "gateway/APM" } }
        sort: { fields: childImageSharp___fluid___originalName }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  return (
    <ApmWrapper className="py-5 secondary-blue-3-background">
      <Container className="py-5 px-5 px-lg-0">
        <Row>
          <Col lg={6} className="pr-lg-5">
            <h2 className="h1 mb-3 color-sky-blue font-weight-bold ambit">APMs</h2>
            <h3 className="mb-3 text-white font-weight-bold ambit">Any type, anywhere.</h3>
            <p className="mb-4 text-white">
              APEXX currently has an ever expanding suite of over <span className="font-weight-bold">120 integrated 
              'Alternative Payment Methods'</span>. In a commercial environment where 
              cash and card no longer simply dominate the payments landscape it 
              is essential for enterprise merchants to have a thorough understanding 
              of which APMs are popular in which of their key markets, and to have 
              a gateway that is technically capable of giving them access to all 
              of the methods they need instantly. 
            </p>
            <p className="my-3 text-white">
              In consumer facing business trust is a must. If your business has
              a through understanding of the types of payment methods it's
              customers like to use in each of its major markets the uptick in
              conversion of sales can be considerable. In terms of the
              anthropology of payments we do not pay the same the world over and
              the forward-thinking enterprise merchant will alway be looking to
              integrate more locally trusted payment methods to drive their
              sales.
            </p>
            <p className="my-3 text-white small-text">
              The global APM market has never been so changeable or diverse, 
              to learn more about payments trends in major markets <Link className="text-secondaryBlue2" to={LINKS.countryReports}>Click Here</Link>
            </p>
          </Col>
          <Col xs={12} lg={{ offset: 1, span: 4 }} className="mb-lg-5 pb-lg-2">
            <Row className="h-100 pl-3 pl-lg-0 justify-content-center justify-content-lg-end">
              {images.map(({ node }) => {
                if (node.childImageSharp) {
                  return (
                    <Col
                      xs = {4}
                      lg={6}
                      className="d-flex justify-items-center align-items-center mt-3 px-2 logo-container"
                    >
                      <div className="logos img-cont rounded d-flex justify-content-center align-items-center mx-auto">
                        <Img
                          fluid={node.childImageSharp.fluid}
                          objectFit="contain"
                          className=" apm-img "
                        />
                      </div>
                    </Col>
                  )
                } else {
                  return null
                }
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </ApmWrapper>
  )
}

export default Apm
