import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const AcquirerAgnosticImage = () => {
  const data = useStaticQuery(graphql`
    {
  allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/IntelligentRouting"}, name: {eq: "AcquirerAgnostic"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}

  `)
  return <img src={data.allFile.edges[0].node.childImageSharp.fluid.src} alt="" className="mt-lg-0 mt-5 pt-4 mr-0 ml-auto mb-5 acquirer-agnostic-img" style={{maxWidth: "100%", width: "550px", position: 'relative'}} />
}

export default AcquirerAgnosticImage
