import React, {useEffect, useState, useRef} from "react";
import { ProductVideoWrapper } from "./ProductVideoStyles"
import {Col, Row, Container} from "react-bootstrap"
import ProductVideoImage from "./ProductVideoImage"


const ProductVideo = () => {

    const videoRef = useRef(null);
    const [thumbnailVisible, setThumbnailVisible] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleFirstPlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setThumbnailVisible(false);
        }
    };

    return(
        <ProductVideoWrapper>
            <Container className="my-5">
                <h2 id="howDoesItWork" class="mb-5 font-weight-bold ambit text-center color-sky-blue">How does it work?</h2>
                <div className="embed-responsive embed-responsive-16by9">
                {thumbnailVisible && (
                    <>
                        <ProductVideoImage/>
                        <div onClick={handleFirstPlay} className="video-layover"></div>
                    </>
                )}
                {isPlaying && (
                    <div className="video-layover active-video"></div>
                )}
                <video 
                    ref={videoRef}
                    onPause={handlePlay}
                    onPlay={handlePause}
                    controls
                    width="300"
                >
                    <source src="https://141862351.fs1.hubspotusercontent-eu1.net/hubfs/141862351/Videos/apexx_product_film_(usa_vo)%20(1080p).mp4" type="video/mp4"/>
                    This browser does not support HTML video.
                </video>
                </div>
            </Container>
        </ProductVideoWrapper>
    )
};

export default ProductVideo