import React from "react";
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/paymentOrchestrationLayer/heroSection/heroSection";
import ThreeItems from "../components/paymentOrchestrationLayer/ThreeItems/ThreeItems";
import IntelligentRouting from "../components/paymentOrchestrationLayer/IntelligentRouting/IntelligentRouting";
import ProductVideo from "../components/paymentOrchestrationLayer/ProductVideo/ProductVideo";
import TrulyGlobal from "../components/paymentOrchestrationLayer/TrulyGlobal/TrulyGlobal";
import Apm from "../components/paymentOrchestrationLayer/APMSection/ApmSection";
import { createContext } from "react"
import { useState } from "react"
import SEO from "../components/Global/SEO/seo"

export const MapContext = createContext()

const MapContextProvider = ({ children }) => {
  const [state, setState] = useState({ location: "Europe", mode: "ACQ" })
  return (
    <MapContext.Provider value={[state, setState]}>
      {children}
    </MapContext.Provider>
  )
}

const paymentOrchestrationPage = () => {

  return (
    <Layout
      headerAbsolute
      headerTransparent
      headerMobile={'ghostWhite'}
      whiteToggler
      //textlight
      logoDark={true}
      helpSectionColor={'white-bg'}
    >
        <SEO page="payment-orchestration-platform" />
        <HeroSection />
        <ThreeItems />
        <IntelligentRouting />
        <ProductVideo />
        <MapContextProvider>
          <TrulyGlobal />
          <Apm />
        </MapContextProvider>
    </Layout>
  )
};

export default paymentOrchestrationPage;