import styled from "styled-components"
import { colors } from "../../../utils/styles";
import ApButton from "../../Global/Common/ApButton/ApButton"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"


const BannerBgImage = () => {
    const data = useStaticQuery(graphql`
      {
    allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/HeroSection"}, name: {eq: "banner-bg-image"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
    `)
    return data.allFile.edges[0].node.childImageSharp.fluid.src
  }

export const HeroWrapper = styled.section`
height: 100%;
max-height: 1200px;
min-height: 600px;
background-color: #F7F9FC;
width: 100%;
position: relative;

@media (min-width: 992px){
    background-image: url(${BannerBgImage});
    background-size: 100%;
    background-repeat: no-repeat;
}

.hero-content{
    height: 100%;
    margin: auto;
    padding-top: 150px;
    position: relative;
    z-index: 1;
    h1{
        font-size: 4rem;
        @media (max-width: 991px){
        font-size: 2.5rem;
    }
    }

    .banner-text{
        font-size: 1.25rem;
    }

    .explore-button{
        margin-top: 25px;
    }

    @media (max-width: 991px){
        padding-top: 0;
    }
}


    &::after{
        content: '';
        width: 100%;
        height: 200px;
        background-color: white;
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        @media (max-width: 991px){
            height: 15%;
        }
    }


`;
