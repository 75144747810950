import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import { TrulyGlobalWrapper } from "./TrulyGlobal.styles"
import { Container, Row, Col } from "react-bootstrap"
import MapSection from "../CustomMap/MapSection"
import { useStaticQuery, graphql } from "gatsby"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { MapContext } from "../../../pages/payment-orchestration-platform"
import Logos from "./logos/logos"
import { FiChevronDown } from "react-icons/fi"
import { colors } from "../../../utils/styles"

const TrulyGlobal = () => {
  const [state, setState] = useContext(MapContext);
  if (state.selectContinent) {
    document.getElementById('local-processors').style.display = 'block';
  }

var logoImages = '';


  if (state.mode === 'ACQ') {
    switch (state.location) {
      case 'Africa':
        logoImages = <Logos logoImages={["Barclays","FirstData","arabAfricanInterBank","worldPay","americanExpress","i&mBank","bitpay","paypal","skrill","access","zenith","firstRandBank","absa","ubas","bidvest","paysera","kcb","nbc"]}/>
        break;
      case 'Asia':
        logoImages = <Logos logoImages={['payeco','credorax','FirstData','worldPay','acgriculturalChina','americanExpress','pinganbank','globalpayments','bea','bocInternational','hsbc','ocbcWingHang','wingLungBank','citi','axisBank','hdfcBank','iciciBank','yesBank','jbc','omc','life','nicos','orico','saisonInsurance','sumitomoLife','toyota','bc','keb','citibank','bocHongKong','maybank','bankDhofar','bankMuscat','hbl','unionBank','bsn','ocbcBank','uob','unionBankTaiwan','cathayUnitedBank','taishinBank','smfg','7Eleven']}/>
        break;
      case 'Europe':
        logoImages = <Logos logoImages={['payplug','elavon','ingenicoGroup','bambora','six','barclaycard','paysafe','aib','borgun','cashflows','credorax','lloydsBank','evo','FirstData','hsbc','ecp','payvision','trustpay','postbank','cm-cic','merchantServices','worldPay','processingCom','transactEurope','trueevo','americanExpress','BNL','ems','bankOfAmerica','rbs','atticaBank','societeGenerale','worldLine']}/>
        break;
      case 'North America':
        logoImages = <Logos logoImages={['elavon','barclaycard','credorax','evo','FirstData','globalpayments','processingCom','worldPay','americanExpress','bankOfAmerica','capitalOne','delta','farelogix','fdc','jpMorgan','metroBank','cityNationalBank','tsys','vantiv','wellsFargo','azul','bitpay']}/>
        break;
      case 'Oceania':
        logoImages = <Logos logoImages={['worldPay','americanExpress','FirstData','nationalAustraliaBank','anz','boq','citibank','globalpayments','jpMorganChaseCo','nab','westpac','bni','bca','cimaNiaga','bankBri','asb','7Eleven']}/>
        break;
      case 'South America':
        logoImages = <Logos logoImages={['mcb','FirstData','cielo','worldPay','americanExpress','lloydsBank','bancoSafra','braspag','bancoPromerica','banamex','bbva','bancoGeneral','visaNet','scotiabank','7Eleven']}/>
        break;
      default:
        logoImages = ''
        break;
    }
  } else if (state.mode === 'APM') {
    switch (state.location) {
      case 'Africa':
        logoImages = ''
        break;
      case 'Asia':
        logoImages = <Logos logoImages={['affinBank','amBank','bangkokBank','bankIslam','cimbClicks','rhbBank','hongLeongBank','krungthaiBank','alipay','krungsri','maybank','dragonPay','scb','tescoLotus','7Eleven','sam','bitpay','paypal','wechatPay','grabPay','skrill','vtPay','eNets','paysera','payU']}/>
        break;
      case 'Europe':
        logoImages = <Logos logoImages={['qiwi','blik','postFinance+','przelevy24','sofort','ideal','giropay','eurosusteen','latvianCommercialBanks','lietuvosBankas','maybank','safetyPay','trustly','trustpay','verkkopankki','afterPay','maxima','narvesen','payPost','bitpay','s€pa','paysafeCard','banContact','paypal','applePay','skrill','zimpler','mb','monexEurope','payByBankApp','paysera','payU']}/>
        break;
      case 'North America':
        logoImages = <Logos logoImages={['paypal','paysafeCard','applePay','skrill','nmi','paysera','7Eleven']}/>
        break;
      case 'Oceania':
        logoImages = <Logos logoImages={['bitpay','paypal','skrill','poli','applePay','paysafeCard','7Eleven']}/>
        break;
      case 'South America':
        logoImages = <Logos logoImages={['paypal','applePay','paysera','bcp','bbvaContinental','bancolombia','caixa','interbank','spei','bancoDoBrasil','bradesco','discover','safetyPay','santander','webpay','cmrFalabella','cordial','cordobesa','hipercard','lider','magna','nativa','oca','presto','itau','payU']}/>
        break;
      default:
        logoImages = ''
        break;
    }
  }


  return (
    <TrulyGlobalWrapper className="py-5">
      <Container className="mt-5">
        <div className="text-left text-lg-center px-5 px-lg-0">
          <h2 className="h1 mb-5 font-weight-bold ambit truly-global">Truly Global Processing Locally</h2>
          <p className="mb-5">
            Doing business in new markets is always fraught with complications
            from fulfilment to logistics to legal obstacles etc. APEXX
            specialises in guiding enterprise merchants into new markets. The
            APEXX Gateway offers truly global coverage to local processors in
            the complex new markets that you are trying to enter. Local
            processing helps merchants avoid exorbitant foreign exchange fees on
            their cross-border transactions, as well as boosting the chances of
            acceptance, and avoiding scheme mandated cross-border fees.
          </p>
          <p className="primary-blue-text mt-5 mb-0 text-center font-weight-bold connecting-leading-acquirers mx-auto">
            We connect with leading acquirers, PSPs and APMs across the world to
            build bespoke solutions best suited to your business.
            <FiChevronDown size="30" color={colors.primaryBlue} className="mx-auto text-center d-block mt-3" />
          </p>

        </div>
        <div className="d-none d-lg-flex w-100 justify-content-center">
        </div>
        <div className="ml-2 mr-4 ml-lg-0 mr-lg-0">
          <MapSection />
        </div>
        <div id="local-processors">
          <div className="pill overflow-hidden d-flex justify-content-center mt-5">
            <ApButton className={`py-2 px-5 border-0 button-select font-weight-bold ${state.mode==="ACQ" ?"selected":"unselected"}`} onClick={()=>
              setState((prevState) => ({ ...prevState, mode: "ACQ" }))
              }
              >
              <span className="poppins text-white">ACQ</span>
            </ApButton>
            <ApButton className={`py-2 px-5 border-0 button-select font-weight-bold ${state.mode==="APM" ?"selected":"unselected"}`} onClick={()=>
              setState((prevState) => ({ ...prevState, mode: "APM" }))
              }
              >
              <span className="poppins text-white">APM</span>
            </ApButton>
          </div>
          <div class="location-header">
            <h4 class="primary-blue-text mt-5 mb-0 font-weight-bold">{state.location}</h4>
          </div>
          {logoImages}
        </div>

      </Container>
    </TrulyGlobalWrapper>
  )
}

export default TrulyGlobal
