import React from "react";
import { HeroWrapper } from "./heroSectionStyles";
import { Row, Col, Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { navigateTo } from "../../../utils/hooks"
import HeroImage from "./HeroImage"
import { LINKS } from "../../../data/pageLinks.data";
import { Link } from "gatsby"

const HeroSection = () => (
    <HeroWrapper className="px-3">
        <Container className="pb-0 px-0 d-flex flex-column justify-content-end hero-content">
            <Row className="mt-5">
                <Col xs={12}>
                    <h1 className="ambit font-weight-bold mb-4 pb-3">Speed. Simplicity. Choice</h1>
                </Col>
                
                    <Col xs={12} lg={5} className="banner-text mt-3">
                        <p className="mb-4">
                        Designed for enterprise merchants to fully optimise their payments stack by integrating them to all of the most beneficial components of their payment's ecosystem.
                        </p>
                        <Link to={LINKS.contactUs}>
                            <ApButton
                                variant="lightGreen"
                                className="font-weight-bold mt-4"
                                onClick={(e)=> navigateTo(e,"/contact-us")}
                            >
                                Explore
                            </ApButton>
                        </Link>
                    </Col>
                    <Col xs={12} lg={7} className="px-0 mt-lg-0 mt-3">
                        <HeroImage/>
                    </Col>
            </Row>
        </Container>
    </HeroWrapper>
);

export default HeroSection;