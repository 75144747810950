import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ProductVideoImage = () => {
  const data = useStaticQuery(graphql`
    {
  allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/ProductVideo"}, name: {eq: "videoPlaceholder"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}

  `)
  return <img src={data.allFile.edges[0].node.childImageSharp.fluid.src} alt="product-video" />
}

export default ProductVideoImage
