import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    {
  allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/HeroSection"}, name: {eq: "main-hero-image"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}

  `)
  return <Image 
    fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} 
    style={{width: "100%"}} 
    />
}

export default HeroImage
