import styled from "styled-components"
import { fonts,colors } from "../../../utils/styles"

export const ApmWrapper = styled.section`
  a {
    color: ${colors.secondaryBlue2};
  }
  h6 {
    color: ${colors.secondaryBlue2};
  }
  p.small-text{
    font-size: .75rem;
  }
  .logo-container {
    width: 154px;
    @media (max-width: 991px) {
      max-width: 154px;
    }
    .logos{
      background: linear-gradient(180deg, rgba(107, 226, 229, 0.15) 0%, rgba(107, 226, 229, 0) 100%);
    }
  }
  .img-cont {
    border: 2px solid #6ef7fa;
    @media (max-width:550px) {
      /* border: 0px; */
      transform: scale(0.8);
    }
    height: 80px;
    width: 140px;
  }
  .apm-img {
    width: 110px;
    object-fit: scale-down !important;
  }
`
