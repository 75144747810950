import React from "react";
import { SectionRapper } from "./ThreeItemsStyles";
import { Row, Col, Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { navigateTo } from "../../../utils/hooks"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ThreeItems = () => {

  const data = useStaticQuery(graphql`
    {
  allFile(filter: {relativeDirectory: {eq: "paymentOrchestrationLayer/ThreeItems"}}) {
    edges {
      node {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`)

  return (
    <SectionRapper className="my-5 px-3">
      <Container>
        <Row className="px-3">
          <Col xs={12} lg={4} className="px-0 pr-lg-5 pl-lg-0">
          <Image fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} style={{width: "40px"}} />
          <p className="py-3"><span className="font-weight-bold">Acquirers</span> Connect to dozens of acquiring bank connections via a single API.</p>
          </Col>
          
          <Col xs={12} lg={4} className="px-0 px-lg-4">
          <Image fluid={data.allFile.edges[1] && data.allFile.edges[1].node.childImageSharp.fluid} style={{width: "40px"}} />
          <p className="py-3"><span className="font-weight-bold">APM’s</span> Ever-expanding suite of over 120 Alternative Payment Methods.</p>
          </Col>
          
          <Col xs={12} lg={4} className="px-0 pl-lg-5 pr-lg-0">
          <Image fluid={data.allFile.edges[2] && data.allFile.edges[2].node.childImageSharp.fluid} style={{width: "40px"}} />
          <p className="py-3"><span className="font-weight-bold">Reporting</span> Consolidated reporting on all connections in a data-rich customisable dashboard.</p>
          </Col>
          
        </Row>
      </Container>
    </SectionRapper>
  )
};

export default ThreeItems;





/* 
const ThreeItems = () => {
  const altTexts = [
    "Visa",
    "Mastercard",
    "American Express",
    "Discover Network",
    "Union Pay",
    "Diners Club International",
  ];
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "buttons"}}, sort: {fields: name}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges
  return (
    <div className="d-flex justify-content-around">
    </div>
  )
}

export default ThreeItems
*/


